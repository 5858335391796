import React from 'react';
import Layout from '../components/Shared/Layout';
import ModifiedScrumModel from '../components/modified_scrum_model';
import '../assets/css/scrum.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class MSMPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'ScrumModel.Process',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <ModifiedScrumModel />
      </Layout>
    );
  }
}

export default MSMPage;
